<template>
  <div class="demo-component">
    <div class="row text-center">
      <div class="col-12">
        <h2 class="font-weight-bold" v-bind:style="{
          color: props.titleColor,
          background: 'dodgerblue',
        }">{{props.title}}</h2>
      </div>
      <div class="col-12 mb-4">
        <p class="lead text-muted">{{props.description}}</p>
      </div>
      <div class="col-12 col-lg-4" v-for="(item, index) in props.items" v-bind:key="index">

        <img v-bind:src="item.imageURL" alt="Image" class="mb-3">

        <p>{{item.content}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      props: Object
    }
  };
</script>
